body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important; */
  /* font-family:'Poppins' !important; */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: subpixel-antialiased !important;
  text-rendering:optimizeLegibility !important;
  min-height: 100%;
}

body h1, h2, h3, h4, h5, h6 {
  font-family: 'Amiri', Georgia, "Times New Roman",serif !important;
  letter-spacing: 0.1rem;
}

body p {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  line-height: 1.75;
}

body a {
  text-decoration: none !important;
}

html {
  height: 100%;
}

#root{
  overflow-x: hidden !important;
}
   
@keyframes slideInFromLeft {
  0% {
    -webkit-transform: translateX(-30%);
    -moz-transform: translateX(-30%);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@keyframes slideInFromTop {
  0% {
      -webkit-transform: translateY(20%);
      -moz-transform: translateY(20%);
  }
  100% {
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
  }
}

@keyframes slideInFromRight {
  0% {
    -webkit-transform: translateX(30%);
    -moz-transform: translateX(30%);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}