  .ng-dental {
    text-align: left;
    padding-left: 5%;
    padding-right: 5%;
  }
  
  .ng-dental h2 {
    size: 80px;
    font-size: 38px;
    line-height: 1.5;
    color: #005160;
    font-weight: 600;
  }
  
  .ng-dental p {
    font-size: 17px;
    font-weight: 400;
  }
  
/*TABLET VIEW*/
@media only screen and (min-width: 760px) {
}

/*DESKTOP VIEW*/
@media only screen and (min-width: 1025px) {
  .ng-dental-start{
    opacity: 0;
  }
  
  .ng-dental-animate {
    animation: 2s ease-out 0s 1 slideInFromRight;
    transition: 2s;

    -webkit-animation: 2s ease-out 0s 1 slideInFromRight;
    -webkit-transition: 2s;

    -moz-animation: 2s ease-out 0s 1 slideInFromRight;
    -moz-transition: 2s;

    opacity: 1.0 !important;
  }

}
  
  /*MOBILE VIEW*/
@media only screen and (max-width: 760px) {
}