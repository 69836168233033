.Service h2 {
  size: 80px;
  font-size: 38px;
  font-weight: lighter;
  line-height: 1.5;
  color: #005160;
  font-weight: 600;
}

.vl {
  border-left: 5px solid #005160;
  height: 50px;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  margin-bottom: 5%;
}

.VerticalLine {
  margin-bottom: 5%;
}

.ServiceDescription {
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
}

.ServiceDescription h2 {
  size: 80px;
  font-size: 38px;
  font-weight: lighter;
  line-height: 1.5;
  color: #005160;
  font-weight: 600;
  margin-bottom: 0;
}

.ServiceDescription p {
  font-size: 17px;
  font-weight: 400;
  color: #0b2f5e;
}

.FunFactsSection {
  background-color: #f7f7f7;
  margin-top: 5%;
  margin-bottom: 5%;
  padding-top: 2.5%;
  padding-bottom: 5%;
}

.FunFacts {
  text-align: center;
  padding-left: 5%;
  padding-right: 5%;
}

.FunFacts h3 {
  font-size: 29px;
  font-weight: 500;
  color: #0b2f5e;
}

.FAQs {
  padding-top: 2.5%;
  padding-bottom: 5%;
  margin-bottom: 5%;
  background-color: #f7f7f7 !important;
}

.FAQs h2 {
  size: 10px;
  font-weight: lighter;
  line-height: 1;
  color: #005160;
  font-weight: 600;
}

.FAQ {
  background-color: #1395b3;
  padding-top: 10%;
  padding-bottom: 15%;
  padding-left: 15%;
  padding-right: 15%;
  margin-bottom: 10%;
  margin-top: 15%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: left;
}

.FAQ h2 {
  color: white;
}

.FAQ p {
  font-size: 18px;
  text-align: left;
  color: white !important;
}

/* The flip box container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-box {
  background-color: transparent;
  height: 225px;
  margin-bottom: 10%;
  margin-top: 15%;
  text-align: left;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

.flip-box:hover {
  cursor: pointer;
}

/* This container is needed to position the front and back side */
.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 1.25s;
  transform-style: preserve-3d;
  -webkit-transition: transform 1.25s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: transform 1.25s;
  -moz-transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180def);
}

/* Position the front and back side */
.flip-box-front, .flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

/* Style the front side */
.flip-box-front {
  background-color: #1395b3;
  padding-top: 7.5%;
  padding-left: 7.5%;
  padding-right: 7.5%;
  text-align: left;
}

/* Style the back side */
.flip-box-back {
  background-color: #1395b3;
  color: white;
  padding-top: 7.5%;
  padding-left: 7.5%;
  padding-right: 7.5%;
  text-align: left;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

.flip-box-front h2, .flip-box-back h2 {
  color: white;
}

.flip-box-front p, .flip-box-back p {
  font-size: 18px;
  text-align: left;
  color: white !important;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.work {
  border: 4px solid white;
}

.work-title {
  text-align: right !important;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 5%;
  width: 50%;
}

.work-title h3{
  font-size: 3rem !important;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important; */
  color: white !important;
  font-weight: 600 !important;
}

/*TABLET VIEW*/
@media only screen and (min-width: 760px) {
  .work-image{
    height: 100% !important;
    width: 100% !important;
    opacity: 1.0;
  }
  .work-image-1{
      height: 100% !important;
      width: 100% !important;
  }
  .work-image-2{
      height: 100% !important;
      width: 100% !important;
  }
  .work-image-3{
      height: 100% !important;
      width: 100% !important;
  }
}

/*DESKTOP VIEW*/
@media only screen and (min-width: 1025px) {
  .service-start{
    opacity: 0;
  }
  
  .service-slide {
    animation: 2s ease-out 0s 1 slideInFromRight;
    transition: 2s;

    -webkit-animation: 2s ease-out 0s 1 slideInFromRight;
    -webkit-transition: 2s;

    -moz-animation: 2s ease-out 0s 1 slideInFromRight;
    -moz-transition: 2s;

    opacity: 1.0 !important;
  }

  .work-image {
    height: 100% !important;
    width: 100% !important;
    opacity: 0;
  }

  .work-image-1 {
      height: 100% !important;
      width: 100% !important;
      transition: 1.25s;
      -webkit-transition: 1.25s;
      -moz-transition: 1.25s;
      opacity: 1.0 !important;
  }
  .work-image-2 {
      height: 100% !important;
      width: 100% !important;
      transition: 2.25s;
      -webkit-transition: 2.25s;
      -moz-transition: 2.25s;
      opacity: 1.0 !important;
  }
  .work-image-3 {
      height: 100%!important;
      width: 100% !important;
      transition: 3.25s;
      -webkit-transition: 3.25s;
      -moz-transition: 3.25s;
      opacity: 1.0 !important;
  }

  .work:hover .work-title{
      transform: translateY(-5px);
      -webkit-transform: translateY(-5px);
      -moz-transform: translateY(-5px);
  }

  .work-title {
      transition: transform 0.25s ease-in-out;
      -webkit-transition: transform 0s ease-in-out;
      -moz-transition: transform .25s ease-in-out;
  }

}
  
  /*MOBILE VIEW*/
@media only screen and (max-width: 760px) {
  .work-image{
    height: 100% !important;
    width: 90% !important;
    opacity: 1.0;
  }
  .work-image-1{
      height: 100% !important;
      width: 90% !important;
  }
  .work-image-2{
      height: 100% !important;
      width: 90% !important;
  }
  .work-image-3{
      height: 100% !important;
      width: 90% !important;
  }

  .work-title {
      right: 10%;
  }
}