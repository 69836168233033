.nopadding {
    padding: 0 !important;
    margin: 0 !important;
}

.services {
    border: 4px solid white;
}

.services-title {
    text-align: right !important;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    right: 5%;
    width: 50%;
}

.services-title h3{
    font-size: 3rem !important;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important; */
    color: white !important;
    font-weight: 600 !important;
}


/*TABLET VIEW*/
@media only screen and (min-width: 760px) {
    .services-image{
        height: 300px !important;
        width: 100% !important;
        opacity: 1.0;
    }
    .services-image-1{
        height: 300px !important;
        width: 100% !important;
    }
    .services-image-2{
        height: 300px !important;
        width: 100% !important;
    }
    .services-image-3{
        height: 300px !important;
        width: 100% !important;
    }
}

/*DESKTOP VIEW*/
@media only screen and (min-width: 1025px) {
    .services-image {
        height: 300px !important;
        width: 100% !important;
        opacity: 0;
    }
    .services-image-1 {
        height: 300px !important;
        width: 100% !important;
        transition: 1.25s;
        -webkit-transition: 1.25s;
        -moz-transition: 1.25s;
        opacity: 1.0 !important;
    }
    .services-image-2 {
        height: 300px !important;
        width: 100% !important;
        transition: 2.25s;
        -webkit-transition: 2.25s;
        -moz-transition: 2.25s;
        opacity: 1.0 !important;
    }
    .services-image-3 {
        height: 300px !important;
        width: 100% !important;
        transition: 3.25s;
        -webkit-transition: 3.25s;
        -moz-transition: 3.25s;
        opacity: 1.0 !important;
    }

    .services:hover .services-title{
        cursor: pointer;
        transform: translateY(-5px);
        -webkit-transform: translateY(-5px);
        -moz-transform: translateY(-5px);
    }

    .services:hover {
        cursor: pointer;
    }

    .services-title {
        transition: transform 0.25s ease-in-out;
        -webkit-transition: transform 0s ease-in-out;
        -moz-transition: transform .25s ease-in-out;
    }
}

/*MOBILE VIEW*/
@media only screen and (max-width: 760px) {
    .services-image{
        height: 250px !important;
        width: 92.5% !important;
        opacity: 1.0;
    }
    .services-image-1{
        height: 250px !important;
        width: 92.5% !important;
    }
    .services-image-2{
        height: 250px !important;
        width: 92.5% !important;
    }
    .services-image-3{
        height: 250px !important;
        width: 92.5% !important;
    }

    .services-title {
        right: 10%;
    }
}