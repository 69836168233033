.reviews {
    padding-top: 5%;
    padding-bottom: 5%;
    background-color: #f7f7f7!important
}

.review {
    background-color: white;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    align-items: center;
    justify-content: center;
}

.review p {
    font-size: 18px;
    text-align: left;
    color: #0b2f5e!important;
    height: auto;
}

.quotation {
    display: inline-block;
    font-size: 70px;
    height: 70px;
    width: 70px;
    position: relative;
    top: 40px;
    color: #1395b3!important;
    border: 2px solid #1395b3!important;
    border-radius: 50%;
    margin-top: -10%;
}

/*TABLET VIEW*/
@media only screen and (min-width: 760px) {
    .review{
        min-height: 175px;
    }
}

/*TABLET VIEW*/
@media only screen and (min-width: 1024px) {
    .review{
        min-height: 300px;
    }
}
  
/*DESKTOP VIEW*/
@media only screen and (min-width: 1025px) {
    .review{
        min-height: 250px;
    }
}
  
/*MOBILE VIEW*/
@media only screen and (max-width: 760px) {
    .review{
        min-height: 250px;
    }
}