.contact {
  padding-left: 5%;
  padding-right: 5%;
}

.contact h2 {
  color: #005160 !important;
  font-style: bold !important;
}

.contact h3 {
  color: #1395b3 !important;
  font-weight: bold !important;
}

.contact a{
  color: #1395b3 !important;
  text-decoration: none;
}

.contact p {
  color: #0b2f5e !important;
}

.office {
  text-align: left !important;
}

.call {
  padding-top: 1% !important;
  text-align: left !important;
}

.hours {
  text-align: left;
}

.daytime {
  display: inline;
}

.day {
  text-align: left !important;
}

.time {
  text-align: right !important;
}

.Map {
  align-self: center;
  margin-top: 2%;
  width: 100%;
  height: 600px;
}

/*TABLET VIEW*/
@media only screen and (min-width: 760px) {
  .shop-lot {
    width: 100%;
  }

  .contact h2 {
    font-size: 30px !important;
  }
  
  .contact h3 {
    font-size: 16px !important;
  }
  
  .contact p {
    font-size: 16px !important;
  }
}

/*DESKTOP VIEW*/
@media only screen and (min-width: 1025px) {
  .shop-lot {
    width: 125%;
  }

  .contact h2 {
    font-size: 34px !important;
  }
  
  .contact h3 {
    font-size: 20px !important;
  }
  
  .contact p {
    font-size: 20px !important;
  }

  .contact-start {
    opacity: 0;
  }

  .contact-slide {
    animation: 2s ease-out 0s 1 slideInFromRight;
    transition: 2s;

    -webkit-animation: 2s ease-out 0s 1 slideInFromRight;
    -webkit-transition: 2s;

    -moz-animation: 2s ease-out 0s 1 slideInFromRight;
    -moz-transition: 2s;

    opacity: 1 !important;
  }
}

/*MOBILE VIEW*/
@media only screen and (max-width: 760px) {
  .shop-lot {
    width: 100%;
  }

  .contact h2 {
    font-size: 34px !important;
  }
  
  .contact h3 {
    font-size: 20px !important;
  }
  
  .contact p {
    font-size: 20px !important;
  }
}



