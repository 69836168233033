.card{
    margin-left:5%;
    margin-right:5%;
    border: none;
}

.card:hover {
    cursor: pointer;
}

.box-border{
    border: 3px solid rgb(97, 151, 196);
    padding: 20px;
}

.card p {
    font-size: 20px !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
    color: #0b2f5e!important;
    font-weight: 350 !important;
}

.card h1{
    font-size: 25px !important;
    color: #005160!important;
    font-weight: bold !important;
}

.card-img {
    margin-top: 1.5rem;
    position: relative;
    z-index: 9;
}

.details {
    border: 1.8px solid white !important; 
    border-radius: 3px;
    box-shadow: 5px 8px #c0c0c0;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: -10%;
    padding: 10px;
    position: relative;
    z-index: 10;
    background-color: white;
    text-align: center;
}

  /*TABLET VIEW*/
@media only screen and (min-width: 760px) {
}

/*DESKTOP VIEW*/
@media only screen and (min-width: 1025px) {
    .doctor-start{
        opacity: 0;
    }

    .left {
        animation: 1.75s ease-out 0s 1 slideInFromLeft;
        transition: 2s;

        -webkit-animation: 1.75s ease-out 0s 1 slideInFromLeft;
        -webkit-transition: 2s;

        -moz-animation: 1.75s ease-out 0s 1 slideInFromLeft;
        -moz-transition: 2s;

        opacity: 1.0 !important;
    }
    
    .middle {
        animation: 1.75s ease-out 0s 1 slideInFromTop;
        transition: 2s;

        -webkit-animation: 1.75s ease-out 0s 1 slideInFromTop;
        -webkit-transition: 2s;

        -moz-animation: 1.75s ease-out 0s 1 slideInFromTop;
        -moz-transition: 2s;

        opacity: 1.0 !important;
    }
    
    .right {
        animation: 1.75s ease-out 0s 1 slideInFromRight;
        transition: 2s;

        -webkit-animation: 1.75s ease-out 0s 1 slideInFromRight;
        -webkit-transition: 2s;

        -moz-animation: 1.75s ease-out 0s 1 slideInFromRight;
        -moz-transition: 2s;

        opacity: 1.0 !important;
    }
}

/*MOBILE VIEW*/
@media only screen and (max-width: 760px) {
}