.credit {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #eaeaea !important;
}

.credit p {
    margin-bottom: 0!important;
    text-align: center;
}

/*MOBILE VIEW*/
@media only screen and (max-width: 760px) {
    .credit p {
        padding-left: 15px;
    }
}