.About {
  text-align: center;
}

.DoctorProfileSection {
  background-color: #f7f7f7;
  text-align: left;
  margin-bottom: 5%;
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
}

.DoctorProfileSection h2 {
  size: 80px;
  font-size: 38px;
  font-weight: lighter;
  line-height: 1.5;
  color: #005160;
  font-weight: 600;
}

.DoctorProfileSection .specialisation {
  font-size: 17px;
  font-weight: 700;
  color: grey;
}

.DoctorProfileSection .specialisation-body {
  font-size: 17px;
  font-weight: 400;
  color: #0b2f5e;
}

.OurStory, .nurses {
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
}

.OurStory h2, .nurses h2 {
  size: 80px;
  font-size: 38px;
  line-height: 1.5;
  color: #005160;
  margin-bottom: 0;
  font-weight: 600;
}

.OurStory p, .nurses p {
  font-size: 17px;
  font-weight: 400;
  color: #0b2f5e;
}

.facilities {
  padding-bottom: 5%;
}

.facility {
  background-color: white;
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 10%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.facility-title{
  font-size: 18px;
  text-align: center;
  color: #0b2f5e!important;
  padding-top: 35px;
  padding-bottom: 10px;
  font-weight: 700;
}

.facility p {
  font-size: 18px;
  text-align: left;
  color: #0b2f5e!important;
  height: auto;
}

.tool {
  display: inline-block;
  font-size: 22px;
  height: 60px;
  width: 60px;
  position: relative;
  top: 25px;
  color: #1395b3!important;
  border: 2px solid #1395b3!important;
  border-radius: 50%;
  margin-top: -10%;
}

.facility-image{
  border: 4px solid white;
}

/*TABLET VIEW*/
@media only screen and (min-width: 760px) {
  .reverse-flex {
    display: flex;
    flex-direction: column-reverse;
  }

  .facility {
    min-height: 225px;
  }
}

/*TABLET VIEW*/
@media only screen and (min-width: 1024px) {
  .facility {
    min-height: 450px;
  }
}

/*DESKTOP VIEW*/
@media only screen and (min-width: 1025px) {
  .reverse-flex {
    display: flex;
    flex-direction: row;
  }

  .about-start, .nurses-start{
    opacity: 0;
  }

  .about-slide, .nurses-slide {
    animation: 2s ease-out 0s 1 slideInFromRight;
    transition: 2s;

    -webkit-animation: 2s ease-out 0s 1 slideInFromRight;
    -webkit-transition: 2s;

    -moz-animation: 2s ease-out 0s 1 slideInFromRight;
    -moz-transition: 2s;

    opacity: 1 !important;
  }

  .left-doctor-slide {
    animation: 2s ease-out 0s 1 slideInFromLeft;
    transition: 2s;

    -webkit-animation: 2s ease-out 0s 1 slideInFromLeft;
    -webkit-transition: 2s;

    -moz-animation: 2s ease-out 0s 1 slideInFromLeft;
    -moz-transition: 2s;

    opacity: 1 !important;
  }

  .right-doctor-slide {
    animation: 2s ease-out 0s 1 slideInFromRight;
    transition: 2s;

    -webkit-animation: 2s ease-out 0s 1 slideInFromRight;
    -webkit-transition: 2s;

    -moz-animation: 2s ease-out 0s 1 slideInFromRight;
    -moz-transition: 2s;

    opacity: 1 !important;
  }

  .facility {
    min-height: 350px;
  }
}

/*MOBILE VIEW*/
@media only screen and (max-width: 760px) {
  .reverse-flex {
    display: flex;
    flex-direction: column-reverse;
  }

  .facility {
    min-height: 350px;
  }
}
