.error {
    height: 100vh;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.error h1{
    font-family: Georgia, 'Times New Roman', Times, serif !important;
    font-size: 7vh;
}

.error p {
    font-family: Georgia, 'Times New Roman', Times, serif !important;
    font-size: 4vh;
}

.error-text {
    display: block;
}