.qualities {
  padding-top: 5%;
  padding-bottom: 5%;
  background-color: #f7f7f7 !important;
}

.quality {
  background-color: white;
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 10%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  align-items: center;
  justify-content: center;
}

.quality p {
  font-size: 18px;
  text-align: center;
  color: #0b2f5e !important;
  height: auto;
  padding-top: 22.5px;
}


.star {
  display: inline-block;
  font-size: 32px;
  height: 50px;
  width: 50px;
  position: relative;
  top: 20px;
  color: #1395b3 !important;
  border: 2px solid #1395b3 !important;
  border-radius: 50%;
  margin-top: -10%;
}

/*TABLET VIEW*/
@media only screen and (min-width: 760px) {
  .quality {
    min-height: 100px;
  }
}

/*TABLET VIEW*/
@media only screen and (min-width: 1024px) {
  .quality {
    min-height: 125px;
  }
}

/*DESKTOP VIEW*/
@media only screen and (min-width: 1025px) {
  .quality {
      min-height: 100px;
  }
}

/*MOBILE VIEW*/
@media only screen and (max-width: 760px) {
  .quality {
    min-height: 100px;
  }
}