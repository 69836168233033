/* .active {
    color: #46b7de !important;
} */
.inactive {
  color: black !important;
  font-family: "Sen", sans-serif;
}

.single-service {
  color: #005160 !important;
  font-family: "Sen", sans-serif;
  font-size: medium;
}

.single-service:hover {
  color: grey !important;
}

#inactive {
  color: black !important;
  font-family: "Sen", sans-serif;
}

.inactive:hover {
  color: grey !important;
}

#inactive:hover {
  color: grey !important;
}

.my-active {
  color: #337ab7 !important;
  font-weight: 700;
  font-family: "Sen", sans-serif;
}

.my-active:hover {
  color: grey !important;
}

#my-active {
  color: #337ab7 !important;
  font-weight: 700;
  font-family: "Sen", sans-serif;
}

#my-active:hover {
  color: grey !important;
}

.navbar {
  position: fixed;
  top: 0;
}

.navbar-brand {
  position: absolute !important;
  top: 0;
  left: 1%;
  /* font-family: 'Lobster';
  font-family: 'Lobster Two'; */
  font-family: "Alex Brush";
}

.bg-text {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.bg-text h1 {
  font-family: "Sen", sans-serif;
  /* font-family: 'Sen' !important; */
  color: #2b2b2b;
}

.bg-span {
  font-weight: 400;
}

.bg-text-strong {
  font-size: 150%;
}

.bg-text a {
  color: #2b2b2b !important;
  text-decoration: none !important;
}

.bg-text-hr {
  height: 0;
  border: 0;
  margin-top: 5% !important;
  border-bottom: 2px solid grey;
}

.home-bg {
  background-image: url("../../images/home.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-bottom: 5% !important;
  position: relative;
}

.contact-bg {
  background-image: url("../../images/contact.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-bottom: 5% !important;
  position: relative;
}

.about-bg {
  background-image: url("../../images/about.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-bottom: 5% !important;
  position: relative;
}

.service-bg {
  background-image: url("../../images/service.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-bottom: 5% !important;
  position: relative;
}

.nav-top {
  background-color: none;
  width: 100%;
}

.nav-sticky {
  background-color: white;
  width: 100%;
  z-index: 10000;
}

/*TABLET VIEW*/
@media only screen and (min-width: 760px) {
  .nav-container {
    display: flex;
    margin-top: 1%;
    position: fixed !important;
    top: 0;
    right: 1%;
  }

  .nav-item {
    padding: 1rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    font-size: 24px;
  }

  .navbar {
    /* padding-bottom: 6%; */
    height: 75px;
  }

  .navbar-brand {
    font-size: 250%;
    margin-top: 2%;
  }

  .bg-text {
    left: 30%;
    margin-top: 0;
  }

  .bg-span {
    font-size: 75%;
    margin-bottom: 2.5%;
  }

  .bg-text-strong {
    font-size: 150%;
  }

  .bg-text-hr {
    margin-top: 10%;
    width: 25%;
  }

  .home-bg,
  .about-bg,
  .contact-bg,
  .service-bg {
    height: 400px;
  }
}

/*DESKTOP VIEW*/
@media only screen and (min-width: 1025px) {
  .nav-container {
    display: flex;
    margin-top: 1%;
    position: fixed !important;
    top: 0;
    right: 1%;
  }

  .nav-item {
    padding: 0.5rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    font-size: large;
  }

  .navbar {
    /* padding-bottom: 2.5%; */
    height: 70px;
  }

  .navbar-brand {
    font-size: 225%;
    margin-top: 0.75%;
  }

  .bg-text {
    left: 30%;
    margin-top: -2.5%;
  }

  .bg-span {
    font-size: 100%;
    margin-bottom: 2.5%;
  }

  .bg-text-strong {
    font-size: 150%;
    font-weight: bolder !important;
  }

  .bg-text-hr {
    width: 25%;
  }

  .home-bg,
  .about-bg,
  .contact-bg,
  .service-bg {
    height: 800px;
  }
}

/*MOBILE VIEW*/
@media only screen and (max-width: 760px) {
  .nav-container {
    justify-content: center;
    text-align: center;
    border: 1px solid lightgrey;
    margin-top: 10%;
    position: relative;
  }

  .navbar-toggler {
    position: fixed !important;
    top: 10px;
    right: 5%;
  }

  .nav-item {
    padding: 0.75rem;
    padding-left: 20px;
    padding-right: 20px;
    font-size: medium;
    border-bottom: 1px solid lightgrey;
    background-color: rgba(255, 255, 255, 0.9);
  }

  .navbar-brand {
    font-size: 175%;
    margin-top: 0.75%;
  }

  .bg-text {
    left: 32.5%;
    margin-top: 0;
  }

  .bg-span {
    font-size: 40%;
    margin-bottom: 2.5%;
  }

  .bg-text-strong {
    font-size: 150%;
  }

  .bg-text-hr {
    margin-top: 10%;
    width: 30%;
  }

  .home-bg,
  .about-bg,
  .contact-bg,
  .service-bg {
    height: 250px;
  }

  .dropdown-menu {
    position: absolute !important;
    left: 25%;
    top: 75%;
  }

  /* .dropdown-item {
  } */
}

/*SUPER SMALL VIEW*/
@media only screen and (max-width: 330px) {
  .bg-text {
    left: 35%;
    margin-top: 0;
  }
}