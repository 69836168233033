.Home {
  text-align: center;
  width: 100%;
  height: 100%;
}

.Home h2 {
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; */
  font-size: 38px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 0;
  color: #005160 ;
}

.Home h3 {
  font-size: 38px;
  font-weight: 600;
  color: #0b2f5e ;
}

.Home p{
  font-weight: 400 ;
  font-size: 24px;
  color: #0b2f5e ;
}

.carousel-container{
  padding-left:15%;
  padding-right: 15%;
}

/*TABLET VIEW*/
@media only screen and (min-width: 760px) {
  .divider {
    margin-bottom: 5%;
  }
}

/*DESKTOP VIEW*/
@media only screen and (min-width: 1025px) {
  .divider {
    margin-bottom: 5%;
  }
}
  
  /*MOBILE VIEW*/
@media only screen and (max-width: 760px) {
  .divider {
    margin-bottom: 10%;
  }
}