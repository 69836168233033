.footer {
  padding-top: 2.5%;
  background-color: #f7f7f7 !important;
  padding-bottom: 5%;
}

.footer h4 {
  font-size: 25px;
  color: #0b2f5e !important;
}

.footer p {
  font-size: 15px;
  color: grey !important;
}

.footer a {
  color: grey !important;
  text-decoration: none;
}

.footer-service {
  cursor: pointer;
}

.day {
  text-align: left !important;
}

.time {
  text-align: right !important;
}